import forge from "node-forge";
import hash from "object-hash";
import buffer from "buffer/";
import firmafiel from "@gobmx-sfp/firmafiel";
import crypto from "crypto-browserify";

export const convertToArrayBuffer = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsArrayBuffer(file);
  });
};

export const convertToBuffer = async (file) => {
  const arr = await convertToArrayBuffer(file);
  const buff = buffer.Buffer.from(arr);
  return buff;
};

export const signDocument = async (dataToSign) => {
  const { certfile, keyfile, password, document } = dataToSign;
  const c = await convertToBuffer(certfile);
  const k = await convertToBuffer(keyfile);
  const pempublica = firmafiel.certBufferToPem({ derBuffer: c });
  const pemprivada = firmafiel.keyBufferToPem({ derBuffer: k });
  /* const cadena = "DOCSOLUTIONS"; */
  try {
    if (
      firmafiel.validaCertificadosFromPem({
        pempublica: pempublica,
        pemprivada: pemprivada,
        passprivada: password,
      })
    ) {
      const cert = firmafiel.pemToForgeCert({ pem: pempublica });

      const today = new Date().getTime();
      const from = cert.validity.notBefore.getTime();
      const to = cert.validity.notAfter.getTime();

      if (today < from || today > to) {
        throw new Error("El certificado ha expirado");
      }

      const privateKey = firmafiel.pemToForgeKey({
        pemkey: pemprivada,
        pass: password,
      });
      const p7 = forge.pkcs7.createSignedData();
      p7.content = forge.util.createBuffer(forge.util.decode64(document));
      p7.addCertificate(cert);
      p7.addSigner({
        key: privateKey,
        certificate: cert,
        digestAlgorithm: forge.pki.oids.sha256,
      });
      p7.sign({ detached: true }); //es importante poner {detached:true} porque si no , se anexan los datos sin encriptar es decir cualquiera con la firma puede ver los datos firmados
      const pem = forge.pkcs7.messageToPem(p7);

      return { status: "ok", firma: pem };
    }
  } catch (e) {
    console.log(e);
    return { status: e };
  }
};

export const extractSignatureFromPem = (dataToExtract) => {
  const { pemSignature } = dataToExtract;
  try {
    const msg = forge.pkcs7.messageFromPem(pemSignature);
    const sig = msg.rawCapture.signature;
    const signatureb64 = forge.util.encode64(sig);
    return signatureb64;
  } catch (err) {
    console.log(err);
    throw new Error("Error al extraer la firma.");
  }
};

export const verifySignature = async ({ pemSignature, certfile, document }) => {
  const c = await convertToBuffer(certfile);
  const pempublica = firmafiel.certBufferToPem({ derBuffer: c });
  const msg = forge.pkcs7.messageFromPem(pemSignature);
  const sig = msg.rawCapture.signature;
  const certfirmado = msg.certificates[0];
  const certpublico = forge.pki.certificateFromPem(pempublica);
  const algo1 = hash(certfirmado);
  const algo2 = hash(certpublico);
  if (algo1 !== algo2) {
    throw new Error(
      "El certificado del firmado no es el mismo que el certificado proporcionado"
    );
  }
  const buf = buffer.Buffer.from(document, "binary");
  const verifier = crypto.createVerify("RSA-SHA256");
  verifier.update(buf);
  const verified = verifier.verify(
    forge.pki.certificateToPem(certpublico),
    sig,
    "binary"
  );
  console.log("Verificado", verified);
  return verified;
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/* export const certBufferToPem = async ({ derBuffer }) => {
  try {
    const x = await convertToBuffer(derBuffer);
    const forgeBuffer = forge.util.createBuffer(x.toString("binary"));
    //hay que codificarlo como base64
    const encodedb64 = forge.util.encode64(forgeBuffer.data);
    console.log(encodedb64)
    const certPEM =
      "" +
      "-----BEGIN CERTIFICATE-----\n" +
      encodedb64 +
      "\n-----END CERTIFICATE-----";
  } catch (e) {
    console.log(e);
    throw new Error("Error al convertir el archivo a PEM");
  }
  return certPEM;
};

//recibe un buffer de una archivo de llave privada y devuelve la llave privada encryptada en formato pem
const keyBufferToPem = async ({ derBuffer }) => {
  try {
    const x = await convertToBuffer(derBuffer);
    //recibe un buffer binario que se tiene que convertir a un buffer de node-forge
    const forgeBuffer = forge.util.createBuffer(x.toString("binary"));
    //hay que codificarlo como base64
    const encodedb64 = forge.util.encode64(forgeBuffer.data);
    //se le agregan '-----BEGIN ENCRYPTED PRIVATE KEY-----\r\n' y '-----END ENCRYPTED PRIVATE KEY-----\r\n'
    //pkcs8PEM es la llave privada encriptada hay que desencriptarla con el password
    const pkcs8PEM =
      "" +
      "-----BEGIN ENCRYPTED PRIVATE KEY-----\r\n" +
      encodedb64 +
      "-----END ENCRYPTED PRIVATE KEY-----\r\n";
    return pkcs8PEM;
  } catch (e) {
    throw new Error(
      "Error al convertir la llave privada de archivo binario a formato pem"
    );
  }
};

//recibe el certificado y la llave privada(formato pem) y el password(string)
//devuelve true si la llave publica del certificad ocorresponde con la llave publica generada por la llave primaria
const validaCertificadosFromPem = ({ pempublica, pemprivada, passprivada }) => {
  const cert = pemToForgeCert({ pem: pempublica });
  const privateKey = pemToForgeKey({
    pemkey: pemprivada,
    pass: passprivada,
  });
  const forgePublicKey = forge.pki.setRsaPublicKey(privateKey.n, privateKey.e);
  return (
    forge.pki.publicKeyToPem(forgePublicKey) ===
    forge.pki.publicKeyToPem(cert.publicKey)
  );
};

//convierte un certificado en formato pem a un certificado forge
const pemToForgeCert = ({ pem }) => {
  try {
    const pki = forge.pki;
    return pki.certificateFromPem(pem);
  } catch (e) {
    console.log(e);
    throw new Error("Error al convertir la cadena PEM a un certificado forge");
  }
};

//recibe la llave primaria encriptada en formato pem
//y devuelve la llave privada (forge) , por lo que necesita el password de la llave privada
const pemToForgeKey = ({ pemkey, pass }) => {
  const pki = forge.pki;
  //privateKey es la llave privada
  const privateKey = null;
  try {
    privateKey = pki.decryptRsaPrivateKey(pemkey, pass);
  } catch (e) {
    throw new Error("Error en la contraseña");
  }
  if (!privateKey) {
    throw new Error("Error en la contraseña");
  }

  return privateKey;
};
 */
